import * as types from '../actions/gameEngineTypes';
import { saveDecreaseCount } from '../../helper/helper';
import { TeamLeaderItem } from 'components/Leaderboard/LeaderboardTeamLeadersList';
import { WeekPlayersActionTypes } from 'redux/actions/Leaderboard';
import { WeekPlayerResponse } from 'redux/actions/Leaderboard/types';
import { Player, SkillEnum } from 'components/Leaderboard/LeaderboardPlayersLeaders';
import { ActiveGame, EliminationOverview, FranchisePerformance, H2HOverview, LeagueResult, LeaveGameData, OverviewLeaguePlayersStockReport, OwnerStats, SeasonPlayerLeader } from 'types';
import { EliminationActionTypes } from 'redux/actions/Elimination';
import { SeasonActionTypes } from 'redux/actions/Season';
import { InvitedGamesTypes } from 'redux/actions/InvitedGames';
import { AllSeasonGames, LastResults, LastResultsElim, LastResultsH2H } from 'components/HistoricalResults/types';
import { PowerRanking, LeagueTeam } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';
import { SeasonInfo, SeasonScoreWeek } from 'screens/Season/types';
import { SeasonTeamStats } from 'screens/Season/Tabs/Standings/SubTabs/types';

export interface GameEngineStateIF {
  loader: number;
  error: string | null;
  success: string | boolean | null;
  resData: any | null;
  divisionInfo: any | null;
  conferenceInfo: any | null;
  weeklyScores: any | null;
  leagueLeaders: any | null;
  teamStat: any | null;
  teamOverview: any | null;
  teamDepthChart: any | null;
  tokenData: any | null;
  teamPlayersReveal: any | null;
  teamRoster: any | null;
  teamStats: any | null;
  teamGamePlan: any | null;
  teamLeaguesHistory: any | null;
  teamLeaders: any | null;
  headToHeadOverview: H2HOverview | null;
  gameScore: any | null;
  gameSeasonScores: any | null;
  allLeagues: any | null;
  ownerTeams: any | null;
  leagueAvailableList: any | null;
  fetchedAllAvatar: any | [];
  AllLeagueAPI: any | [];
  GameByIdAPI: any | [];
  SelectFranchise: any | [];
  PregameOverview: any | [];
  joinLeagueAPI: any | null;
  SecondUserJoinLeague: any | null;
  UserRecord: any | null;
  usersReadyState: any | [];
  PlayerStats: any | null;
  GameTeams: any | [];
  GameTeamsReady: any | [];
  pregameStatusLoader: boolean;
  FullTeamStats: any | null;
  FullTeamStatsLoader: boolean;
  FullTeamStatsError: any | null;
  TeamActiveGames: ActiveGame[] | [];
  TeamActiveGamesLoader: boolean;
  gameSeasonScoresByGid: any | null;
  teamLeadersViewMoreType: string | null;
  teamLeadersViewMoreData: TeamLeaderItem[] | null;
  teamLeadersViewMoreLoader: boolean;
  player_leaders: any | null;
  player_leaders_type: keyof typeof SkillEnum | null;
  player_leaders_type_data: Player[] | null;
  leaveGameData: LeaveGameData | null;
  franchisePerformance: FranchisePerformance | null;
  perGameLeaders: any | null;
  lastGameResults: LastResults<LastResultsH2H> | null;
  lastEliminationResults: LastResults<LastResultsElim> | null;
  lastSeasonResults:LastResults<AllSeasonGames> | null,
  frontOffice: any | null;
  ownerStats: OwnerStats | null;
  inProgressH2HGames: any | null;
  inProgressH2HGamesByTeam: any | null;
  teamTrophies: string[] | [];
  teamOfWeek: any | null;
  gameOfWeekID: any | null;
  weeklyLeadersWins: any | null;
  weeklyLeadersRatings: any | null;
  teamLeadersWins: any | null;
  teamLeadersPlayed: any | null;
  teamLeadersScored: any | null;
  teamLeadersConsWins: any | null;
  teamLeadersWinrate: any | null;
  teamLeadersPlayoffs: any | null;
  teamLeadersViewMoreNoNextPage: any | null;
  playersStockReport: OverviewLeaguePlayersStockReport | null;
  week_players: any | null;
  createLeagueRes: any | null;
  eliminationOverview: EliminationOverview | null;
  eliminationLeaders: any | null;
  leagueTeams: LeagueTeam[] | null;
  powerRankings: PowerRanking[] | null;
  leagueResults: { data: LeagueResult[]; hasNextPage?: boolean } | null;
  invitedGames: any | null;
  invitedGamesNumber: any | null;
  seasonInfo: SeasonInfo | null;
  seasonLeaders: SeasonPlayerLeader[];
  seasonWeeksWithGames: SeasonScoreWeek[];
  conferenceStats: SeasonTeamStats[];
  activeGamesDetails: any[] | [];
  loaders: {
    getGameSeasonScores: boolean;
    playersStockReport: boolean;
  }
}

const initialState: GameEngineStateIF = {
  loader: 0,
  error: null,
  success: null,
  resData: null,
  divisionInfo: null,
  conferenceInfo: null,
  weeklyScores: null,
  leagueLeaders: null,
  teamStat: null,
  teamOverview: null,
  teamDepthChart: null,
  tokenData: null,
  teamPlayersReveal: null,
  teamRoster: null,
  teamStats: null,
  teamGamePlan: null,
  teamLeaguesHistory: null,
  teamLeaders: null,
  headToHeadOverview: null,
  gameScore: null,
  gameSeasonScores: null,
  allLeagues: null,
  ownerTeams: null,
  leagueAvailableList: null,
  teamTrophies: [],
  fetchedAllAvatar: [],
  AllLeagueAPI: [],
  GameByIdAPI: [],
  SelectFranchise: [],
  PregameOverview: [],
  joinLeagueAPI: null,
  SecondUserJoinLeague: null,
  UserRecord: null,
  usersReadyState: [],
  GameTeams: [],
  GameTeamsReady: [],
  pregameStatusLoader: false,
  PlayerStats: null,
  FullTeamStats: null,
  FullTeamStatsLoader: false,
  FullTeamStatsError: null,
  TeamActiveGames: [],
  TeamActiveGamesLoader: false,
  gameSeasonScoresByGid: null,
  teamLeadersViewMoreType: null,
  teamLeadersViewMoreData: null,
  teamLeadersViewMoreLoader: false,
  player_leaders: null,
  player_leaders_type: null,
  player_leaders_type_data: null,
  leaveGameData: null,
  franchisePerformance: null,
  perGameLeaders: null,
  lastGameResults: null,
  lastEliminationResults: null,
  lastSeasonResults:null,
  frontOffice: null,
  ownerStats: null,
  inProgressH2HGames: null,
  inProgressH2HGamesByTeam: null,
  teamOfWeek: null,
  gameOfWeekID: null,
  weeklyLeadersWins: null,
  weeklyLeadersRatings: null,
  teamLeadersWins: null,
  teamLeadersPlayed: null,
  teamLeadersScored: null,
  teamLeadersConsWins: null,
  teamLeadersWinrate: null,
  teamLeadersPlayoffs: null,
  teamLeadersViewMoreNoNextPage: null,
  playersStockReport: null,
  week_players: null,
  createLeagueRes: null,
  eliminationOverview: null,
  eliminationLeaders: null,
  leagueTeams: null,
  powerRankings: null,
  leagueResults: null,
  invitedGames: null,
  invitedGamesNumber: null,
  seasonInfo: null,
  seasonLeaders: [],
  seasonWeeksWithGames: [],
  conferenceStats: [],
  activeGamesDetails: [],
  loaders: {
    getGameSeasonScores: false,
    playersStockReport: false,
  }
};

const reducer = (state = initialState, action: any): GameEngineStateIF => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, ...action.payload };

    case types.GETLEAGUESTANDINGDIVISION_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETLEAGUESTANDINGDIVISION_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        divisionInfo: action.payload.divisionInfo,
      };
    case types.GETLEAGUESTANDINGDIVISION_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETLEAGUESTANDINGCONFERENCE_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETLEAGUESTANDINGCONFERENCE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        conferenceInfo: action.payload.conferenceInfo,
      };
    case types.GETLEAGUESTANDINGCONFERENCE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.TeamsActiveGames_REQUEST:
      return { ...state, TeamActiveGamesLoader: true };
    case types.TeamsActiveGames_SUCCESS:
      return {
        ...state,
        TeamActiveGamesLoader: false,
        TeamActiveGames: action.payload.result,
      };
    case types.TeamsActiveGames_FAIL:
      return { ...state, TeamActiveGamesLoader: false, TeamActiveGames: [] };
    case types.GETPLAYERSTATS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETPLAYERSTATS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        PlayerStats: action.payload,
      };
    case types.GETPLAYERSTATS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETLEAGUEWEEKLYSCORES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETLEAGUEWEEKLYSCORES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        weeklyScores: action.payload.weeklyScores,
      };
    case types.GETLEAGUEWEEKLYSCORES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.SELECTLEAGUEAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.SELECTLEAGUEAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        leagueAvailableList: action.payload.success,
      };
    case types.SELECTLEAGUEAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.PregameOverview_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null, PregameOverview: null };
    case types.PregameOverview_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        PregameOverview: action.payload.success,
      };
    case types.PregameOverview_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.PregameStatus_REQUEST:
      return {
        ...state,
        pregameStatusLoader: true,
        success: null,
        error: null,
      };
    case types.PregameStatus_SUCCESS:
      return {
        ...state,
        pregameStatusLoader: false,
        success: true,
        error: null,
        PregameOverview: {
          ...state.PregameOverview,
          ...action.payload.success,
        },
      };
    case types.PregameStatus_FAIL:
      return {
        ...state,
        pregameStatusLoader: true,
        success: null,
        error: null,
      };

    case types.SelectFranchise_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.SelectFranchise_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        SelectFranchise: action.payload.success,
      };
    case types.SelectFranchise_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.AllLeagueAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.AllLeagueAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        AllLeagueAPI: action.payload.success,
      };
    case types.AllLeagueAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.GameByIdAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GameByIdAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        GameByIdAPI: action.payload.success,
      };
    case types.GameByIdAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.SecondUserJoinLeagueAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.SecondUserJoinLeagueAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        SecondUserJoinLeague: action.payload.SecondUserJoinLeague,
      };
    case types.SecondUserJoinLeagueAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.UserRecordAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.UserRecordAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        UserRecord: action.payload.userRecord,
      };
    case types.UserRecordAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.userReadyState_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.userReadyState_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        usersReadyState: action.payload.success,
      };
    case types.userReadyState_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.JOINLEAGUEAPI_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.JOINLEAGUEAPI_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        joinLeagueAPI: action.payload.fetchedAllAvatar,
      };
    case types.JOINLEAGUEAPI_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.readyGamePlay_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.readyGamePlay_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        joinLeagueAPI: null,
        PregameOverview: {
          ...state.PregameOverview,
          ready: state.PregameOverview?.ready?.map((item: any) => {
            if (item?.utid === action.payload?.utid) {
              return { ...item, ready: true };
            }
            return item;
          }),
        },
      };
    case types.readyGamePlay_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.readyBothGamePlay_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        joinLeagueAPI: null,
        PregameOverview: {
          ...state.PregameOverview,
          ready: state.PregameOverview?.ready?.map((item: any) => {
            item.ready = true;
            return item;
          }),
        },
      };

    case types.GETLEAGUELEADERS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETLEAGUELEADERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        leagueLeaders: action.payload.leagueLeaders,
      };
    case types.GETLEAGUELEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMSTAT_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMSTAT_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamStat: action.payload.teamStat,
      };
    case types.GETTEAMSTAT_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMOVERVIEW_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMOVERVIEW_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamOverview: action.payload.teamOverview,
      };
    case types.GETTEAMOVERVIEW_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMDEPTHCHART_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMDEPTHCHART_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamDepthChart: action.payload.teamDepthChart,
      };
    case types.GETTEAMDEPTHCHART_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GAMEENGINETOKEN_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GAMEENGINETOKEN_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        tokenData: action.payload.tokenData,
      };
    case types.GAMEENGINETOKEN_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMPLAYERSREVEAL_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMPLAYERSREVEAL_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamPlayersReveal: action.payload.teamPlayersReveal,
      };
    case types.GETTEAMPLAYERSREVEAL_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        teamPlayersReveal: null,
      };

    case types.GETTEAMROSTER_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMROSTER_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamRoster: action.payload.teamRoster,
      };
    case types.GETTEAMROSTER_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMGAMEPLAN_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMGAMEPLAN_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamGamePlan: action.payload.teamGamePlan,
      };
    case types.GETTEAMGAMEPLAN_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.PUTTEAMGAMEPLAN_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.PUTTEAMGAMEPLAN_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamGamePlan: action.payload.teamGamePlan,
      };
    case types.PUTTEAMGAMEPLAN_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.PUTTEAMDEPTHCHART_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.PUTTEAMDEPTHCHART_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamDepthChart: action.payload.teamDepthChart,
      };
    case types.PUTTEAMDEPTHCHART_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMLEAGUESHISTORY_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMLEAGUESHISTORY_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeaguesHistory: action.payload.teamLeaguesHistory,
      };
    case types.GETTEAMLEAGUESHISTORY_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        teamLeaguesHistory: null,
      };

    case types.GETTEAMLEADERS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMLEADERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeaders: action.payload.teamLeaders,
      };
    case types.GETTEAMLEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_PER_GAME_LEADERS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_PER_GAME_LEADERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        perGameLeaders: action.payload.perGameLeaders,
      };
    case types.GET_PER_GAME_LEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        perGameLeaders: null,
      };

    case types.GET_LAST_GAME_RESULTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_LAST_GAME_RESULTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        lastGameResults: action.payload.lastGameResults,
      };
    case types.GET_LAST_GAME_RESULTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        lastGameResults: null,
      };

    case types.GET_LAST_ELIMINATION_RESULTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_LAST_ELIMINATION_RESULTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        lastEliminationResults: action.payload.lastEliminationResults,
      };
    case types.GET_LAST_ELIMINATION_RESULTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        lastEliminationResults: null,
      };
      
    case types.GET_LAST_SEASON_RESULTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_LAST_SEASON_RESULTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        lastSeasonResults: action.payload.lastSeasonResults,
      };
    case types.GET_LAST_SEASON_RESULTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        lastEliminationResults: null,
      };

    case types.GETHEADTOHEADOVERVIEW_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETHEADTOHEADOVERVIEW_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        headToHeadOverview: action.payload.headToHeadOverview,
      };
    case types.GETHEADTOHEADOVERVIEW_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        headToHeadOverview: null,
      };

    case types.GETGAMESCORES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETGAMESCORES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        gameScore: action.payload.gameScore,
      };
    case types.GETGAMESCORES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETGAMESEASONSCORES_REQUEST:
      return { 
        ...state, 
        loaders: {
          ...state.loaders,
          getGameSeasonScores: true,
        },
        success: null, 
        error: null 
      };
    case types.GETGAMESEASONSCORES_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGameSeasonScores: false,
        },
        success: true,
        gameSeasonScores: action.payload.gameSeasonScores,
      };
    case types.GETGAMESEASONSCORES_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGameSeasonScores: false,
        },
        error: action.error,
      };

    case types.GETALLLEAGUES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETALLLEAGUES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        allLeagues: action.payload.allLeagues,
      };
    case types.GETALLLEAGUES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETOWNERTEAMS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETOWNERTEAMS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        ownerTeams: action.payload.ownerTeams,
      };
    case types.GETOWNERTEAMS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETTEAMFRONTOFFICE_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETTEAMFRONTOFFICE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        frontOffice: action.payload.frontOffice,
      };
    case types.GETTEAMFRONTOFFICE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.FETCHALLSELECTEDAVATAR_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.FETCHALLSELECTEDAVATAR_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        fetchedAllAvatar: action.payload.fetchedAllAvatar,
      };
    case types.FETCHALLSELECTEDAVATAR_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GETOWNERSTATS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETOWNERSTATS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        ownerStats: action.payload.ownerStats,
      };
    case types.GETOWNERSTATS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        ownerStats: null,
      };

    case types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        inProgressH2HGamesByTeam: action.payload.inProgressH2HGamesByTeam,
      };
    case types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        inProgressH2HGamesByTeam: null,
      };

    case types.GET_TEAM_TROPHIES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_TROPHIES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamTrophies: action.payload.data,
      };
    case types.GET_TEAM_TROPHIES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        teamTrophies: [],
      };

    case types.GET_GAME_TEAMS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_GAME_TEAMS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        GameTeams: action.payload.success,
      };
    case types.GET_GAME_TEAMS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        GameTeams: null,
      };

    case types.GET_GAME_TEAMS_READY_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_GAME_TEAMS_READY_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        GameTeamsReady: action.payload.success,
      };
    case types.GET_GAME_TEAMS_READY_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        GameTeamsReady: null,
      };

    case types.FULLTEAMSTATS_REQUEST:
      return {
        ...state,
        FullTeamStatsLoader: true,
        FullTeamStats: null,
        FullTeamStatsError: null,
      };
    case types.FULLTEAMSTATS_SUCCESS:
      return {
        ...state,
        FullTeamStatsLoader: false,
        FullTeamStats: action.payload,
        FullTeamStatsError: null,
      };
    case types.FULLTEAMSTATS_FAIL:
      return {
        ...state,
        FullTeamStatsLoader: false,
        FullTeamStats: null,
        FullTeamStatsError: action.error,
      };

    case types.ShortPregameOverview_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.ShortPregameOverview_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        error: null,
        PregameOverview: {
          ...state.PregameOverview,
          ...action.payload.success,
          teams: {
            '0': {
              ...state.PregameOverview?.teams?.[0],
              ...action.payload.success?.teams?.[0],
            },
            '1': {
              ...state.PregameOverview?.teams?.[1],
              ...action.payload.success?.teams?.[1],
            },
          },
        },
      };
    case types.ShortPregameOverview_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };

    case types.GETGAMESEASONSCORESBYGID_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETGAMESEASONSCORESBYGID_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        gameSeasonScoresByGid: action.payload.gameSeasonScoresByGid,
      };
    case types.GETGAMESEASONSCORESBYGID_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.CLEAN_PREGAMEOVERVIEW:
      return { ...state, PregameOverview: null };

    case types.TOGGLE_LOADER_ON:
      return { ...state, loader: state.loader + 1 };

    case types.TOGGLE_LOADER_OFF:
      return { ...state, loader: saveDecreaseCount(state.loader) };

    case types.TOGGLE_LOADER_OFF_WITH_ERROR:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_OF_WEEK_DATA_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_OF_WEEK_DATA_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamOfWeek: action.payload.teamOfWeek,
      };
    case types.GET_TEAM_OF_WEEK_DATA_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_GAME_OF_WEEK_ID_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_GAME_OF_WEEK_ID_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        gameOfWeekID: action.payload.gameOfWeekID,
      };
    case types.GET_GAME_OF_WEEK_ID_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_WEEKLY_LEADERS_WINS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_WEEKLY_LEADERS_WINS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        weeklyLeadersWins: action.payload.weeklyLeadersWins,
      };
    case types.GET_WEEKLY_LEADERS_WINS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_WEEKLY_LEADERS_RATINGS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_WEEKLY_LEADERS_RATINGS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        weeklyLeadersRatings: action.payload.weeklyLeadersRatings,
      };
    case types.GET_WEEKLY_LEADERS_RATINGS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_LEADERS_WINS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_WINS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersWins: action.payload.teamLeadersWins,
      };
    case types.GET_TEAM_LEADERS_WINS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_LEADERS_PLAYED_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_PLAYED_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersPlayed: action.payload.teamLeadersPlayed,
      };
    case types.GET_TEAM_LEADERS_PLAYED_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_LEADERS_SCORED_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_SCORED_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersScored: action.payload.teamLeadersScored,
      };
    case types.GET_TEAM_LEADERS_SCORED_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_LEADERS_CONS_WINS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_CONS_WINS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersConsWins: action.payload.teamLeadersConsWins,
      };
    case types.GET_TEAM_LEADERS_CONS_WINS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.GET_TEAM_LEADERS_WINRATE_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_WINRATE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersWinrate: action.payload.teamLeadersWinrate,
      };
    case types.GET_TEAM_LEADERS_WINRATE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_TEAM_LEADERS_PLAYOFFS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_TEAM_LEADERS_PLAYOFFS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        teamLeadersPlayoffs: action.payload.teamLeadersPlayoffs,
      };
    case types.GET_TEAM_LEADERS_PLAYOFFS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.SET_TEAM_LEADERS_VIEW_MORE_TYPE:
      return {
        ...state,
        teamLeadersViewMoreType: action.payload.teamLeadersViewMoreType,
      };

    case types.TEAM_LEADERS_VIEW_MORE_REQUEST:
      return {
        ...state,
        teamLeadersViewMoreLoader: true,
        success: null,
        error: null,
      };

    case types.SET_TEAM_LEADERS_VIEW_MORE_DATA:
      let noData = false;
      if (Array.isArray(action.payload.teamLeadersViewMoreData) && action.payload.teamLeadersViewMoreData.length === 0) {
        noData = true;
      } else if (!action.payload.teamLeadersViewMoreData) {
        noData = true;
      }
      if (Array.isArray(state.teamLeadersViewMoreData) && Array.isArray(action.payload.teamLeadersViewMoreData)) {
        return {
          ...state,
          teamLeadersViewMoreData: [...state.teamLeadersViewMoreData, ...action.payload.teamLeadersViewMoreData],
          loader: saveDecreaseCount(state.loader),
          teamLeadersViewMoreLoader: false,
          teamLeadersViewMoreNoNextPage: noData,
        };
      }
      return {
        ...state,
        teamLeadersViewMoreData: action.payload.teamLeadersViewMoreData,
        loader: saveDecreaseCount(state.loader),
        teamLeadersViewMoreLoader: false,
        teamLeadersViewMoreNoNextPage: noData,
      };

    case types.CLEAN_TEAM_LEADERS_VIEW_MORE_DATA:
      return {
        ...state,
        teamLeadersViewMoreData: null,
        success: null,
        error: null,
        teamLeadersViewMoreNoNextPage: false,
      };

    case types.GET_PLAYERS_LEADERS_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case types.GET_PLAYERS_LEADERS_SUCCESS:
      console.log('Leaders from reducer - ', action.payload?.leaders);
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        player_leaders: action.payload?.leaders,
      };
    case types.GET_PLAYERS_LEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        player_leaders: null,
        error: action?.error ?? 'Unexpected error',
      };

    case types.SET_PLAYERS_LEADERS_TYPE:
      return {
        ...state,
        player_leaders_type: action.payload.player_leaders_type,
      };

    case types.SET_PLAYERS_LEADERS_TYPE_DATA:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        player_leaders_type_data: action.payload.player_leaders_type_data,
      };
    case WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        week_players: null,
      };
    case WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        week_players: action.payload?.players as WeekPlayerResponse,
      };
    case WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        week_players: null,
        error: action?.error ?? 'Unexpected error',
      };
    case types.LEAVE_GAME_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case types.LEAVE_GAME_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        leaveGameData: action.payload.leaveGameData,
      };
    case types.LEAVE_GAME_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action?.error ?? 'Unexpected error',
      };
    case types.LEAVE_GAME_CLEAN:
      return {
        ...state,
        leaveGameData: null,
      };
    case types.CREATE_LEAGUE_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case types.CREATE_LEAGUE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        createLeagueRes: action.payload.createLeagueRes,
      };
    case types.CREATE_LEAGUE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action?.error ?? 'Unexpected error',
      };
    case types.CLEAN_CREATE_LEAGUE_DATA:
      return {
        ...state,
        createLeagueRes: null,
      };
    case EliminationActionTypes.ELIMINATION_OVERVIEW_REQUEST:
      return {
        ...state,
      };
    case EliminationActionTypes.ELIMINATION_OVERVIEW_SUCCESS:
      return {
        ...state,
        eliminationOverview: action.payload.eliminationOverview,
      };
    case EliminationActionTypes.ELIMINATION_OVERVIEW_FAIL:
      return {
        ...state,
        error: action?.error ?? 'Unexpected error',
      };
    case EliminationActionTypes.GET_ELIMINATION_LEADERS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case EliminationActionTypes.GET_ELIMINATION_LEADERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        eliminationLeaders: action.payload.eliminationLeaders,
      };
    case EliminationActionTypes.GET_ELIMINATION_LEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };
    case EliminationActionTypes.GET_ELIMINATION_TEAMS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case EliminationActionTypes.GET_ELIMINATION_TEAMS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        leagueTeams: action.payload.leagueTeams,
      };
    case EliminationActionTypes.GET_ELIMINATION_TEAMS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };
    case EliminationActionTypes.GET_ELIMINATION_POWER_RANKINGS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case EliminationActionTypes.GET_ELIMINATION_POWER_RANKINGS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        powerRankings: action.payload.eliminationPowerRankings,
      };
    case EliminationActionTypes.GET_ELIMINATION_POWER_RANKINGS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };
    case EliminationActionTypes.GET_ELIMINATION_RESULTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case EliminationActionTypes.GET_ELIMINATION_RESULTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        leagueResults: { data: action.payload.eliminationResults },
      };
    case EliminationActionTypes.GET_ELIMINATION_RESULTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };
    case InvitedGamesTypes.GET_INVITED_GAMES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case InvitedGamesTypes.GET_INVITED_GAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        invitedGames: action.payload.invitedGames,
      };
    case InvitedGamesTypes.GET_INVITED_NUMBER_GAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        invitedGamesNumber: action.payload.invitedGamesNumber,
      };
    case InvitedGamesTypes.GET_INVITED_GAMES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        invitedGames: null,
      };

    case types.GET_FRANCHISE_PERFORMANCE_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case types.GET_FRANCHISE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        franchisePerformance: action.payload.franchisePerformance,
        loader: saveDecreaseCount(state.loader),
      };
    case types.GET_FRANCHISE_PERFORMANCE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.GET_SEASON_INFO_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case types.GET_SEASON_INFO_SUCCESS:
      return {
        ...state,
        seasonInfo: action.payload.seasonInfo,
        loader: saveDecreaseCount(state.loader),
      };
    case types.GET_SEASON_INFO_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case SeasonActionTypes.GET_SEASON_TEAM_STATS_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case SeasonActionTypes.GET_SEASON_TEAM_STATS_SUCCESS:
      return {
        ...state,
        conferenceStats: action.payload.conferences || [],
        loader: saveDecreaseCount(state.loader),
      };
    case SeasonActionTypes.GET_SEASON_TEAM_STATS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case SeasonActionTypes.GET_SEASON_TEAMS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case SeasonActionTypes.GET_SEASON_TEAMS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        leagueTeams: action.payload.leagueTeams,
      };
    case SeasonActionTypes.GET_SEASON_TEAMS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };
    case SeasonActionTypes.GET_SEASON_LEADERS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case SeasonActionTypes.GET_SEASON_LEADERS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        seasonLeaders: action.payload.seasonLeaders,
      };
    case SeasonActionTypes.GET_SEASON_LEADERS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
        eliminationLeaders: null,
      };

    case SeasonActionTypes.GET_SEASON_WEEKS_WITH_GAMES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case SeasonActionTypes.GET_SEASON_WEEKS_WITH_GAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        seasonWeeksWithGames: action.payload.seasonWeeksWithGames,
      };
    case SeasonActionTypes.GET_SEASON_WEEKS_WITH_GAMES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case SeasonActionTypes.GET_SEASON_RESULTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case SeasonActionTypes.GET_SEASON_RESULTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        leagueResults: action.payload.leagueResults,
      };
    case SeasonActionTypes.GET_SEASON_RESULTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case SeasonActionTypes.GET_SEASON_POWER_RANKINGS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case SeasonActionTypes.GET_SEASON_POWER_RANKINGS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        powerRankings: action.payload.powerRankings,
      };
    case SeasonActionTypes.GET_SEASON_POWER_RANKINGS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.GET_TEAM_ACTIVE_GAMES_DETAILS_REQUEST:
      return { ...state, loader: state.loader + 1 };
    case types.GET_TEAM_ACTIVE_GAMES_DETAILS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        activeGamesDetails: action.payload.result,
      };
    case types.GET_TEAM_ACTIVE_GAMES_DETAILS_FAIL:
      return { ...state, 
        loader: saveDecreaseCount(state.loader), 
        activeGamesDetails: [] 
      };
    case types.GET_PLAYERS_STOCK_REPORT_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          playersStockReport: true,
        },
      }
    case types.GET_PLAYERS_STOCK_REPORT_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          playersStockReport: false,
        },
        playersStockReport: action.payload.playersStockReport,
      }
    case types.GET_PLAYERS_STOCK_REPORT_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          playersStockReport: false,
        },
        error: action.error,
      }
    default:
      return state;
  }
};

export default reducer;
