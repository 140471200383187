import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import Title from 'components/Common/Title/Title';
import { convertCamelCaseToUpperCase, getFullGameType, shortenString } from 'helper/helper';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PregameOverview, userReadyState } from 'redux/actions/GameEngineAction';
import { fetchAllSelectedAvatar } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';
import history from 'routes/history';
import { ActiveGame, UserTeamsList } from 'types';
import { MappedGameItem, prepareActiveGames } from './helper';

const types: ActiveGame['type'][] = ['H', 'E', 'S'];

type Props = {
  activeGames: ActiveGame[];
};

const getTeamNames = (game: ActiveGame, userTeams: UserTeamsList) => {
  if (!game.teams) {
    return [];
  }

  return userTeams.filter((team) => game.teams?.some((id) => team?.engId === id)).map((team) => team.teamName);
};

const getLink = (game: ActiveGame, userTeams: UserTeamsList, dispatch: any) => {
  if (game?.type === 'H') {
    if (game?.status === 'inProgress') {
      return (
        <Link style={{ color: 'inherit' }} to={`/gameplay?id=${game.gid}`}>
          {game.gid}
        </Link>
      );
    }
    if (game.status === 'pregame') {
      return (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            dispatch(fetchAllSelectedAvatar(game?.teams));
            dispatch(userReadyState(game?.gid));
            dispatch(PregameOverview(game?.gid));
            history.push('/leagueheadtohead', game?.gid);
          }}
        >
          {game?.gid}
        </span>
      );
    }
    if (game.status === '') {
      return (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            history.push(`/EnteredLeague?gid=${game?.gid}&type=${game?.type}`);
          }}
        >
          {game?.gid}
        </span>
      );
    }
  } else if (game?.type === 'E') {
    if (game?.status === 'waitingForPlayers') {
      return (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            dispatch(PregameOverview(game?.gid));
            history.push(`/EnteredLeague?gid=${game?.gid}&type=${game?.type}`);
          }}
        >
          {game?.gid}
        </span>
      );
    }
    return (
      <Link style={{ color: 'inherit' }} to={`/leagueelimination?gid=${game.gid}`}>
        {game.gid}
      </Link>
    );
  } else if (game?.type === 'S') {
    if (game?.status === 'pending') {
      return (
        <Link style={{ color: 'inherit' }} to={`/EnteredLeague?gid=${game?.gid}&type=${game?.type}`}>
          {shortenString(String(game.gid), 10)}
        </Link>
      );
    }

    return (
      <Link style={{ color: 'inherit' }} to={`/season/${game.gid}`}>
        {shortenString(String(game.gid), 10)}
      </Link>
    );
  }
};

export const TeamActiveGamesList: React.FC<Props> = ({ activeGames }) => {
  const dispatch = useDispatch();
  const UserTeams: UserTeamsList = useSelector((state: RootState) => state.team.userTeamsList) || [];

  const preparedGames = prepareActiveGames(activeGames);

  return (
    <div className="mb-4" style={{ maxWidth: '180px' }}>
      {types.map((type, index) => {
        if (Object.values(preparedGames[type]).flat().length === 0) {
          return null;
        }

        const existingKeys = Object.keys(preparedGames[type]) as (keyof MappedGameItem)[];

        return (
          <Fragment key={index}>
            <Title text={getFullGameType(type)} className="mt-4" />
            {existingKeys.map((key) => {
              if (preparedGames[type][key] == null || preparedGames[type][key]?.length === 0) {
                return null;
              }

              return (
                <div key={key}>
                  <h5>{convertCamelCaseToUpperCase(key)}</h5>
                  {preparedGames[type][key]?.map((game) => {
                    const teamNames = getTeamNames(game, UserTeams);

                    return (
                      <div key={game?.gid}>
                        {getLink(game, UserTeams, dispatch)}:<span>&nbsp;&nbsp;{teamNames.join(', ')}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
};
