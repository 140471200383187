import { Tab, Nav } from 'react-bootstrap'
import SettingProfile from './TabContent/SettingProfile'
import SettingWallets from './TabContent/WalletTab/SettingWallets'
import SettingTransaction from './TabContent/SettingTransaction'
import NotificationMain from './TabContent/Notification/NotificationMain'
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import WithdrawFunds from './TabContent/WalletTab/WithdrawFunds'
import { AddNewFundsTab } from './TabContent/WalletTab/AddNewFundsTab'
import { InviteFriendsModalContent } from 'components/InviteFriends/InviteFriendsModalContent'
import { useInviteFriendsForm } from 'components/InviteFriends/hooks/use-invite-friends-form'

enum TabEnum {
   PROFILE = 'profile',
   WALLETS = 'wallets',
   TRANSACTIONS = 'transactions',
   NOTIFICATIONS = 'notifications',
   ADD_FUNDS = 'addFunds',
   WITHDRAW_FUNDS = 'withdrawFunds',
   INVITE_FRIENDS = 'inviteFriends',
}

const SettingTab: React.FC = () => {

   const history = useHistory()
   const location = useLocation()

   const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

   const tabParam = queryParams.get('tab')

   const redirectBackParam = queryParams.get('redirectBack')

   const [tabKey, setTabKey] = useState<TabEnum>(validatedTab(tabParam) ?? TabEnum.PROFILE)
   const [redirectBack] = useState<string | null>(validatedRedirectBack(redirectBackParam))

   const updateURL = useCallback(
      (newTabKey: TabEnum, newRedirectBack: string | null) => {
         const newSearch = new URLSearchParams(queryParams)
         newSearch.set('tab', newTabKey)
         if (newRedirectBack) {
            newSearch.set('redirectBack', newRedirectBack)
         } else {
            newSearch.delete('redirectBack')
         }
         history.replace(`/setting?${newSearch.toString()}`)
      },
      [history, queryParams]
   )

   function handleTabChange(key: TabEnum) {
      setTabKey(key)
   }

   useEffect(() => {
      updateURL(tabKey, redirectBack)
   }, [tabKey, redirectBack, history, updateURL]);

   const {
      emails,
      handleEmailsChange,
      message,
      handleMessageChange,
      handleSubmit,
      errors,
      isLoading: isInviteLoading,
   } = useInviteFriendsForm();

   return (
      <div className="standingTabbarMain mb-5">
         <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={TabEnum.PROFILE}
            activeKey={tabKey}
            onSelect={key => handleTabChange((key as TabEnum) || TabEnum.PROFILE)}
         >
            <h5 className="settinghead" id="top-of-settings">Settings</h5>
            <div className="standingTabbar">
               <Card>
                  <CardBody>
                     <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                           <Nav.Link eventKey={TabEnum.PROFILE}>PROFILE</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                           <Nav.Link eventKey={TabEnum.WALLETS}>WALLETS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                           <Nav.Link eventKey={TabEnum.TRANSACTIONS}>TRANSACTIONS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                           <Nav.Link eventKey={TabEnum.NOTIFICATIONS}>NOTIFICATIONS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                           <Nav.Link eventKey={TabEnum.INVITE_FRIENDS}>INVITE FRIENDS</Nav.Link>
                        </Nav.Item>
                     </Nav>
                  </CardBody>
               </Card>
            </div>
            <div className="settingmain">
               <Card>
                  <CardBody>
                     <Tab.Content>
                        <Tab.Pane eventKey={TabEnum.PROFILE}>
                           <SettingProfile />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.WALLETS}>
                           <SettingWallets
                              openAddNewFundsTab={() => handleTabChange(TabEnum.ADD_FUNDS)}
                              openWithdrawFundsTab={() => handleTabChange(TabEnum.WITHDRAW_FUNDS)}
                           />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.TRANSACTIONS}>
                           <SettingTransaction />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.NOTIFICATIONS}>
                           <NotificationMain />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.ADD_FUNDS}>
                           <AddNewFundsTab />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.WITHDRAW_FUNDS}>
                           <WithdrawFunds />
                        </Tab.Pane>
                        <Tab.Pane eventKey={TabEnum.INVITE_FRIENDS}>
                           <InviteFriendsModalContent
                              emails={emails}
                              errors={errors}
                              handleEmailsChange={handleEmailsChange}
                              handleMessageChange={handleMessageChange}
                              handleSubmit={handleSubmit}
                              message={message}
                              isLoading={isInviteLoading}
                           />
                        </Tab.Pane>
                     </Tab.Content>
                  </CardBody>
               </Card>
            </div>
         </Tab.Container>
      </div>
   )
}

function validatedTab(queryTab: string | null): TabEnum | null {
   if (!queryTab) return null
   const validOptions = Object.values(TabEnum) as string[]
   const isValid = validOptions.includes(queryTab)
   return isValid ? (queryTab as TabEnum) : null
}

function validatedRedirectBack(queryTab: string | null) {
   if (!queryTab) return null
   const validOptions = ['purchaseFranchise', 'league']
   const isValid = validOptions.includes(queryTab)
   const newRedirectParam = 'ownFranchise'

   if (isValid) return queryTab
   if (!isValid) return newRedirectParam
   return null
}

export default SettingTab
