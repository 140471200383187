/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from '../../../Common/Card/Card';
import CardBody from '../../../Common/Card/CardBody';
import { Row, Col } from 'react-bootstrap';
import OverviewLeagueLeaders from '../../../LeagueStanding/TabDetail/OverviewTab/OverviewLeagueLeaders';
import LeagueEnteredView from 'components/LeagueEnter/LeagueEnteredView';
import OverviewLeagueLastPlayedGame from './OverviewLeagueLastPlayedGame';
import OverviewLeaguePlayersStockReport from './OverviewLeaguePlayersStockReport';
import { memo } from 'react';
import OverviewLeagueTrophies from './OverviewLeagueTrophyes';

const OverviewTabMain: React.FC = () => (
  <Row>
    <Col xl={12} className="mb-5">
      <Card>
        <CardBody>
          <LeagueEnteredView />
          <OverviewLeagueLastPlayedGame />
          <OverviewLeaguePlayersStockReport />
          <OverviewLeagueLeaders />
          <OverviewLeagueTrophies/>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default memo(OverviewTabMain);
