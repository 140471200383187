import { get, post, put } from "../services/Api";
import * as types from "./gameEngineTypes";
import { API_URL } from "../../config";
import {
  FranchisePerformance,
  Position,
  TeamDepthChart,
  TeamDepthChartList,
  TeamGamePlan,
} from "../../types";
import { TeamLeaderItem } from "components/Leaderboard/LeaderboardTeamLeadersList";
import { SkillEnum } from "components/Leaderboard/LeaderboardPlayersLeaders";
import { ViewMoreTypes } from "components/Leaderboard/LeaderboardContent";
import { updateWalletBalance } from "./AppAction";
import {
  addHelmetsForScores,
  getTeamOwner,
  roundRatingsToTwoDigitsAfterDot,
} from "helper/helper";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import history from "routes/history";
import { notification } from "antd";
import { TeamStatPriod } from "components/FranchiseOverview/TabDetail/OverviewTab/constatns";

export const getLeagueStandingDivision = (leagueId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETLEAGUESTANDINGDIVISION_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/leagues/${leagueId}/standings`);
      dispatch({
        type: types.GETLEAGUESTANDINGDIVISION_SUCCESS,
        payload: { divisionInfo: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETLEAGUESTANDINGDIVISION_FAIL,
        error: error,
      });
    }
  };
};

export const getPlayerStats = (playerId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETPLAYERSTATS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/players/${playerId}`);
      dispatch({
        type: types.GETPLAYERSTATS_SUCCESS,
        payload: res,
      });
    } catch (error: any) {
      dispatch({
        type: types.GETPLAYERSTATS_FAIL,
        error: error,
      });
    }
  };
};

export const getFullTeamStats = (utid: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.FULLTEAMSTATS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${utid}/teamStat`);
      dispatch({
        type: types.FULLTEAMSTATS_SUCCESS,
        payload: res.teamStats || {},
      });
    } catch (error: any) {
      dispatch({
        type: types.FULLTEAMSTATS_FAIL,
        error: error,
      });
    }
  };
};

export const getLeagueStandingConference = (leagueId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETLEAGUESTANDINGCONFERENCE_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/leagues/${leagueId}/standings?sortType=conference`);
      dispatch({
        type: types.GETLEAGUESTANDINGCONFERENCE_SUCCESS,
        payload: { conferenceInfo: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETLEAGUESTANDINGCONFERENCE_FAIL,
        error: error,
      });
    }
  };
};

export const getLeagueWeeklyScores = (leagueId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETLEAGUEWEEKLYSCORES_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/leagues/${leagueId}/stats/scores?week=1`);
      dispatch({
        type: types.GETLEAGUEWEEKLYSCORES_SUCCESS,
        payload: { weeklyScores: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETLEAGUEWEEKLYSCORES_FAIL,
        error: error,
      });
    }
  };
};

export const getLeagueLeaders = (leagueId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETLEAGUELEADERS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/leagues/${leagueId}/stats/leaders?category=all&limit=1`);
      dispatch({
        type: types.GETLEAGUELEADERS_SUCCESS,
        payload: { leagueLeaders: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETLEAGUELEADERS_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamStat = (leagueId: any, teamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMSTAT_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${teamId}/stats`);
      dispatch({
        type: types.GETTEAMSTAT_SUCCESS,
        payload: { teamStat: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMSTAT_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamOverview = (leagueId: any, teamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMOVERVIEW_REQUEST,
        payload: {},
      });
      const owner: { userName: string; userId: number } | {} = await getTeamOwner(teamId);

      const res: any = await get(`/engine/team/${teamId}/overview`);
      dispatch({
        type: types.GETTEAMOVERVIEW_SUCCESS,
        payload: { teamOverview: { ...res, ...owner } },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMOVERVIEW_FAIL,
        error: error,
      });
    }
  };
};

export const reportUserProblem = (userId: string, imgDataUrl: string, comment: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.POST_USER_PROBLEM_REQUEST,
        payload: {},
      });
      const res: any = await post(`/engine/admin/metrics/reportProblem`, {
        userId: userId,
        imgDataUrl: imgDataUrl,
        userComment: comment,
      });
      console.log(res);
      dispatch({
        type: types.POST_USER_PROBLEM_SUCCESS,
        payload: {},
      });
    } catch (error: any) {
      dispatch({
        type: types.POST_USER_PROBLEM_FAIL,
        error: error,
      });
    }
  };
};

const transformDepthCharts = (data: Array<{ sid: number; depth: TeamDepthChart }>) => {
  const depthChartData: TeamDepthChartList = new Array(data.length);
  data.forEach((depthData) => {
    depthChartData[depthData.sid] = depthData.depth;
  });
  depthChartData.forEach((depth) => {
    for (let position in depth) {
      depth[position as Position]?.forEach((player) => {
        if (Object.keys(player).length > 0) {
          player.ratings = roundRatingsToTwoDigitsAfterDot(player.ratings);
        }
      });
    }
  });

  return depthChartData; // as TeamDepthChartList
};

export const getTeamDepthChart = (engineTeamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMDEPTHCHART_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${engineTeamId}/depthChart`);
      dispatch({
        type: types.GETTEAMDEPTHCHART_SUCCESS,
        payload: { teamDepthChart: transformDepthCharts(res) },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMDEPTHCHART_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamPlayersReveal = (engineTeamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMPLAYERSREVEAL_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${engineTeamId}/players?mode=reveal`);
      dispatch({
        type: types.GETTEAMPLAYERSREVEAL_SUCCESS,
        payload: { teamPlayersReveal: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMPLAYERSREVEAL_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamRoster = (engineTeamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMROSTER_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${engineTeamId}/roster`);
      dispatch({
        type: types.GETTEAMROSTER_SUCCESS,
        payload: { teamRoster: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMROSTER_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamGamePlan = (engineTeamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMGAMEPLAN_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${engineTeamId}/gamePlan`);

      dispatch({
        type: types.GETTEAMGAMEPLAN_SUCCESS,
        payload: { teamGamePlan: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMGAMEPLAN_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamSpecificGamePlan = (engineTeamId: any, gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMGAMEPLAN_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${engineTeamId}/gameSpecificPlan?gid=${gid}`);

      dispatch({
        type: types.GETTEAMGAMEPLAN_SUCCESS,
        payload: { teamGamePlan: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMGAMEPLAN_FAIL,
        error: error,
      });
    }
  };
};

export const putTeamGameSpecificPlan = (gameId: number, engineTeamId: number, data: TeamGamePlan['schemes']) => {
  console.warn(data);
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.PUTTEAMGAMEPLAN_REQUEST,
        payload: {},
      });

      // const res: any =
      await put(`/engine/team/${engineTeamId}/gameSpecificPlan?gid=${gameId}`, data);

      dispatch({
        type: types.PUTTEAMGAMEPLAN_SUCCESS,
        payload: { teamGamePlan: { schemes: data } },
      });
      dispatch(PregameOverview(gameId));
    } catch (error: any) {
      dispatch({
        type: types.PUTTEAMGAMEPLAN_FAIL,
        error: error,
      });
    }
  };
};

export const putTeamDepthChart = (engineTeamId: any, data: TeamDepthChartList) => {
  console.log('🚀 ~ putTeamDepthChart data', data);
  return async (dispatch: any, getState: any) => {
    // const teamGamePlan: TeamGamePlan = getState()?.gameEngine?.teamGamePlan
    const depthChartData = data
      .map((depth, sid) => {
        const playersAtPosition: any = {};
        for (let position in depth) {
          playersAtPosition[position] = depth[position as Position]?.map((player) => player.pid);
        }
        return {
          sid,
          depth: playersAtPosition,
        };
      })
      .filter((d) => d);

    console.log('PUT depthChartData', depthChartData);
    try {
      dispatch({
        type: types.PUTTEAMDEPTHCHART_REQUEST,
        payload: {},
      });

      // const res: any =
      await put(`/engine/team/${engineTeamId}/depthChart`, depthChartData);

      dispatch({
        type: types.PUTTEAMDEPTHCHART_SUCCESS,
        payload: { teamDepthChart: data },
      });
    } catch (error: any) {
      dispatch({
        type: types.PUTTEAMDEPTHCHART_FAIL,
        error: error,
      });
    }
  };
};

export const putTeamGameDepthChart = (gameId: number, engineTeamId: number, data: TeamDepthChartList) => {
  console.log('🚀 ~ putTeamGameDepthChart data', data);
  return async (dispatch: any, getState: any) => {
    const depthChartData = data
      .map((depth, sid) => {
        const playersAtPosition: any = {};
        for (let position in depth) {
          playersAtPosition[position] = depth[position as Position]?.map((player) => player.pid);
        }
        return {
          sid,
          depth: playersAtPosition,
        };
      })
      .filter((d) => d);

    console.log('PUT teamGameDepthChartData', depthChartData);

    try {
      dispatch({
        type: types.PUTTEAMDEPTHCHART_REQUEST,
        payload: {},
      });

      await put(`/engine/team/${engineTeamId}/gameDepthChart`, {
        depth: depthChartData,
        gid: gameId,
      });

      dispatch({
        type: types.PUTTEAMDEPTHCHART_SUCCESS,
        payload: { teamDepthChart: data },
      });
      dispatch(PregameOverview(gameId));
    } catch (error: any) {
      dispatch({
        type: types.PUTTEAMDEPTHCHART_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamGameDepthChart = (engineTeamId: any, gid: any, type: string = '') => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMDEPTHCHART_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${engineTeamId}/gameDepthChart`, !type.length ? { gid } : { gid, type });
      dispatch({
        type: types.GETTEAMDEPTHCHART_SUCCESS,
        payload: {
          teamDepthChart: type === 'fullplayerstat' ? res : transformDepthCharts(res),
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMDEPTHCHART_FAIL,
        error: error,
      });
    }
  };
};

export const putTeamGamePlan = (engineTeamId: any, data: TeamGamePlan['schemes']) => {
  // engineTeamId = 5670335
  console.log('🚀 ~ data', data);
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.PUTTEAMGAMEPLAN_REQUEST,
        payload: {},
      });

      // const res: any =
      await put(`/engine/team/${engineTeamId}/gamePlan`, data);

      dispatch({
        type: types.PUTTEAMGAMEPLAN_SUCCESS,
        payload: { teamGamePlan: { schemes: data } },
      });
    } catch (error: any) {
      dispatch({
        type: types.PUTTEAMGAMEPLAN_FAIL,
        error: error,
      });
    }
  };
};

export const getTeamLeaguesHistory = (teamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMLEAGUESHISTORY_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${teamId}`);

      dispatch({
        type: types.GETTEAMLEAGUESHISTORY_SUCCESS,
        payload: { teamLeaguesHistory: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMLEAGUESHISTORY_FAIL,
        error: error,
      });
    }
  };
};

export const getPerGameLeaders = (utid: number, period?: TeamStatPriod) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_PER_GAME_LEADERS_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/h2h/${utid}/perGameLeaders`, { period });

      dispatch({
        type: types.GET_PER_GAME_LEADERS_SUCCESS,
        payload: { perGameLeaders: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_PER_GAME_LEADERS_FAIL,
        error: error,
      });
    }
  };
};

export const getActiveH2HGamesByTeam = (utid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/${utid}/inProgressGames`);

      dispatch({
        type: types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_SUCCESS,
        payload: { inProgressH2HGamesByTeam: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_H2H_IN_PROGRESS_GAMES_BY_TEAM_FAIL,
        error: error,
      });
    }
  };
};

export const getTrophiesByTeamUtid = (utid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_TEAM_TROPHIES_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${utid}/trophies`);

      dispatch({
        type: types.GET_TEAM_TROPHIES_SUCCESS,
        payload: { data: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_TROPHIES_FAIL,
        error: error,
      });
    }
  };
};

export const getLastGameResults = (utid: number, limit: number = 10, page: number = 1) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_LAST_GAME_RESULTS_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/${utid}/lastGameResults`, { limit, page });

      dispatch({
        type: types.GET_LAST_GAME_RESULTS_SUCCESS,
        payload: { lastGameResults: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_LAST_GAME_RESULTS_FAIL,
        error: error,
      });
    }
  };
};

export const getLastEliminationResults = (utid: number, limit: number = 10, page: number = 1) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_LAST_ELIMINATION_RESULTS_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/${utid}/lastEliminationResults`, { limit, page });

      dispatch({
        type: types.GET_LAST_ELIMINATION_RESULTS_SUCCESS,
        payload: { lastEliminationResults: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_LAST_ELIMINATION_RESULTS_FAIL,
        error: error,
      });
    }
  };
};

export const getLastSeasonResults = (utid: number, limit: number = 10, page: number = 1) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_LAST_SEASON_RESULTS_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/${utid}/lastSeasonResults`, { limit, page });

      dispatch({
        type: types.GET_LAST_SEASON_RESULTS_SUCCESS,
        payload: { lastSeasonResults: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_LAST_SEASON_RESULTS_FAIL,
        error: error,
      });
    }
  };
};

export const getHeadToHeadOverview = (gameId: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETHEADTOHEADOVERVIEW_REQUEST,
        payload: {},
      });

      let res: any = await get(`/engine/games/h2h/${gameId}/headToHeadOverview`);

      dispatch({
        type: types.GETHEADTOHEADOVERVIEW_SUCCESS,
        payload: { headToHeadOverview: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETHEADTOHEADOVERVIEW_FAIL,
        error: error,
      });
    }
  };
};

export const getGameScores = (leagueId: any, gameId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETGAMESCORES_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/games/${leagueId}/${gameId}/scores`);

      dispatch({
        type: types.GETGAMESCORES_SUCCESS,
        payload: { gameScore: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETGAMESCORES_FAIL,
        error: error,
      });
    }
  };
};

export const getGameSeasonScores = (page: number, teamIds: number[], onlyLastGame = false, type: League['type']) => {
  return async (dispatch: any, getState: any) => {
    if (getState().gameEngine.loaders.getGameSeasonScores) return;
    let res = {
      data: []
    };
    try {
      dispatch({
        type: types.GETGAMESEASONSCORES_REQUEST,
        payload: {},
      });
      if (teamIds) {
        res = await get(
          `/engine/games/h2h/seasonScores?type=${type}`,
          {
            page,
            teamIds: teamIds.join(","),
            pageSize: onlyLastGame ? 1 : null, 
          }
        );
      } else {
        res = await get(
          `/engine/games/h2h/seasonScores?page=${page}?type=${type}`,
        );
      }

      const resWithAvatars = await addHelmetsForScores(res.data);

      dispatch({
        type: types.GETGAMESEASONSCORES_SUCCESS,
        payload: { gameSeasonScores: { ...res, data: resWithAvatars } },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETGAMESEASONSCORES_FAIL,
        error: error,
      });
    }
  };
};

export const getAllLeagues = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETALLLEAGUES_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/leagues`);
      dispatch({
        type: types.GETALLLEAGUES_SUCCESS,
        payload: { allLeagues: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETALLLEAGUES_FAIL,
        error: error,
      });
    }
  };
};

export const getOwnerTeams = () => {
  return async (dispatch: any, getState: any) => {
    const ownerId = await getState().app.resData?.id;
    try {
      dispatch({
        type: types.GETOWNERTEAMS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/owner/${ownerId}/teams`);
      dispatch({
        type: types.GETOWNERTEAMS_SUCCESS,
        payload: { ownerTeams: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETOWNERTEAMS_FAIL,
        error: error,
      });
    }
  };
};

export const getOwnerStats = (userId: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETOWNERSTATS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/owner/${userId}/stats`);
      dispatch({
        type: types.GETOWNERSTATS_SUCCESS,
        payload: { ownerStats: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETOWNERSTATS_FAIL,
        error: error,
      });
    }
  };
};

export const getFrontOffice = (teamId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETTEAMFRONTOFFICE_REQUEST,
        payload: {},
      });

      const res: any = await get(`/engine/team/${teamId}/frontOffice`);

      dispatch({
        type: types.GETTEAMFRONTOFFICE_SUCCESS,
        payload: { frontOffice: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETTEAMFRONTOFFICE_FAIL,
        error: error,
      });
    }
  };
};

export const selectLeagueAPI = (engId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.SELECTLEAGUEAPI_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/h2h/${engId}/available`);
      dispatch({
        type: types.SELECTLEAGUEAPI_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.SELECTLEAGUEAPI_FAIL,
        error: 'Something went Wrong',
      });
    }
  };
};

export const AllLeagueAPI = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.AllLeagueAPI_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/all`);
      dispatch({
        type: types.AllLeagueAPI_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.AllLeagueAPI_FAIL,
        error: 'Something went Wrong',
      });
    }
  };
};

export const getUserActiveGames = (ownerId: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.TeamsActiveGames_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/owner/${ownerId}/activeGames`);
      dispatch({
        type: types.TeamsActiveGames_SUCCESS,
        payload: {
          result: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.TeamsActiveGames_FAIL,
        error: 'Something went Wrong',
      });
    }
  };
};

export const getGameByIdAndType = (gid: string | number, type: League['type']) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GameByIdAPI_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/${gid}?type=${type}`);
      dispatch({
        type: types.GameByIdAPI_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GameByIdAPI_FAIL,
        error: 'Game not found',
      });
    }
  };
};

interface JoinLeagueApi {
  gid: number | string;
  utid: number;
  type: League['type'];
  isLastTeam: boolean;
  leagueData?: any;
  joinCode?: string;
  qb?: () => void;
}

type JoinRes = {
  game?: {
    gid: number | string;
    type: League['type'];
    isFilled: boolean
  },
  success: boolean;
}

export const joinLeagueAPI = ({ gid, utid, type, joinCode, leagueData, qb }: JoinLeagueApi) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.JOINLEAGUEAPI_REQUEST,
        payload: {},
      });
      const res: any = await post<JoinRes>(`/engine/leagues/join`, { gid: gid, utid: utid, type, joinCode });
      dispatch({
        type: types.JOINLEAGUEAPI_SUCCESS,
        payload: { fetchedAllAvatar: res },
      });

      if (res && res.success && res?.game) {
        if (res.game.isFilled) {
          if (type === 'H') {
            history?.push('/leagueheadtohead', gid);
          } else if (type === 'E') {
            history?.push(`/leagueelimination?gid=${gid}`);
          } else if (type === 'S') {
            history?.push(`/season/${gid}`);
          }
        } else {
          history?.push(`/EnteredLeague?gid=${leagueData?.gid}&type=${leagueData?.type}`);
        }
      }
    } catch (error: any) {
      dispatch({
        type: types.JOINLEAGUEAPI_FAIL,
        error: error.response.data,
      });
      notification.error({
        placement: 'bottomRight',
        message: error?.response?.data || 'Something went wrong joining game',
      });
    } finally {
      qb && qb();
    }
  };
};

export const UserRecordAPI = (utid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.UserRecordAPI_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${utid}/record`);
      dispatch({
        type: types.UserRecordAPI_SUCCESS,
        payload: { userRecord: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.UserRecordAPI_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const SelectFranchise = (engId: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.SelectFranchise_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${engId}/info`);
      dispatch({
        type: types.SelectFranchise_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      // @TODO add notification for all errors
      dispatch({
        type: types.SelectFranchise_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const PregameOverview = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.PregameOverview_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/games/h2h/${gid}/pregameOverview`);

      try {
        const avatars: any = res?.teams && (await Promise.all(Object.keys(res.teams).map((t) => get(`${API_URL}/user/get-avatar/${res?.teams?.[t]?.utid}`))));

        res = res?.teams && {
          ...res,
          teams: {
            '0': {
              ...res.teams[0],
              avatar: avatars && avatars[0]?.data?.img,
              helmet: avatars && avatars[0]?.data?.helmet,
            },
            '1': {
              ...res.teams[1],
              avatar: avatars && avatars[1]?.data?.img,
              helmet: avatars && avatars[1]?.data?.helmet,
            },
          },
        };
      } catch (e) {}

      dispatch({
        type: types.PregameOverview_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.PregameOverview_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const PregameStatus = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.PregameStatus_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/games/h2h/${gid}/pregameStatus`);

      dispatch({
        type: types.PregameStatus_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.PregameStatus_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const GameTeams = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_GAME_TEAMS_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/games/h2h/${gid}/gameTeams`);
      try {
        res = res?.teams && {
          ...res.teams,
        };
      } catch (e) {}

      dispatch({
        type: types.GET_GAME_TEAMS_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_GAME_TEAMS_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const GameTeamsReady = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_GAME_TEAMS_READY_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/games/h2h/${gid}/gameTeamsReady`);
      try {
        res = res?.ready && {
          ...res.ready,
        };
      } catch (e) {}

      dispatch({
        type: types.GET_GAME_TEAMS_READY_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_GAME_TEAMS_READY_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const userReadyState = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.userReadyState_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/h2h/${gid}/readyState`);
      dispatch({
        type: types.userReadyState_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.userReadyState_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const readyGamePlay = (utid: any, gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.readyGamePlay_REQUEST,
        payload: {},
      });
      const setReadyStatusForTeam = async (teamId: any) => {
        await post(`/engine/games/h2h/ready`, { utid: teamId, gid: gid });
      };

      if (Array.isArray(utid) && utid[0] && utid[1]) {
        await setReadyStatusForTeam(utid[0]);
        await setReadyStatusForTeam(utid[1]);
        dispatch({
          type: types.readyBothGamePlay_SUCCESS,
        });
        history.push(`/gameplay?id=${gid}`);
        return;
      }

      await setReadyStatusForTeam(utid);
      dispatch({
        type: types.readyGamePlay_SUCCESS,
        payload: { utid },
      });
    } catch (error: any) {
      dispatch({
        type: types.readyGamePlay_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const shortPregameOverview = (gid: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.ShortPregameOverview_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/games/h2h/${gid}/shortPregameOverview`);

      try {
        const avatars: any = res?.teams && (await Promise.all(Object.keys(res.teams).map((t) => get(`${API_URL}/user/get-avatar/${res?.teams?.[t]?.utid}`))));

        res = res?.teams && {
          ...res,
          teams: {
            '0': {
              ...res.teams[0],
              avatar: avatars && avatars[0]?.data?.img,
              helmet: avatars && avatars[0]?.data?.helmet,
            },
            '1': {
              ...res.teams[1],
              avatar: avatars && avatars[1]?.data?.img,
              helmet: avatars && avatars[1]?.data?.helmet,
            },
          },
        };
      } catch (e) {}

      dispatch({
        type: types.ShortPregameOverview_SUCCESS,
        payload: {
          success: res,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.ShortPregameOverview_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const getGameSeasonScoresByGid = (gid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETGAMESEASONSCORESBYGID_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/h2h/seasonScoresByGameId?gid=${gid}`);

      const resWithAvatars = await addHelmetsForScores(res);

      dispatch({
        type: types.GETGAMESEASONSCORESBYGID_SUCCESS,
        payload: { gameSeasonScoresByGid: resWithAvatars },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETGAMESEASONSCORESBYGID_FAIL,
        error: error,
      });
    }
  };
};

export const cleanPregameOverview = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.CLEAN_PREGAMEOVERVIEW,
    });
  };
};

export const getTeamOfWeekData = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_TEAM_OF_WEEK_DATA_REQUEST,
        payload: {},
      });
      let res: any = await get(`/engine/team/teamOfTheWeek`);

      if (res) {
        const dataFromMySQL: any = await get(
          `${API_URL}/user/team-of-week-data/${res?.utid}`,
          {},
          {
            'Access-Control-Allow-Origin': '*',
          },
        );
        if (dataFromMySQL) {
          res = { ...res, ...dataFromMySQL };
        }
      }
      dispatch({
        type: types.GET_TEAM_OF_WEEK_DATA_SUCCESS,
        payload: { teamOfWeek: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_OF_WEEK_DATA_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getGameOfWeekId = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_GAME_OF_WEEK_ID_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/games/h2h/gameOfTheWeek`);
      dispatch({
        type: types.GET_GAME_OF_WEEK_ID_SUCCESS,
        payload: { gameOfWeekID: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_GAME_OF_WEEK_ID_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

interface addTeamInputData {
  engId: number;
  value: string | number;
}

const addTeamsAndHelmetsData = async (dataInput: addTeamInputData[]): Promise<TeamLeaderItem[] | void> => {
  const preparedEngIds = dataInput.map((data) => data.engId).join(',');
  if (!preparedEngIds) {
    return;
  }

  const helmetsData: any = await get(
    `${API_URL}/user/get-helmet/${preparedEngIds}`,
    {},
    {
      'Access-Control-Allow-Origin': '*',
    },
  );

  if (helmetsData) {
    const result: TeamLeaderItem[] = dataInput.map((data: any, index: any) => {
      const helmetData = helmetsData.find((h: any) => h.engId === +data.engId);

      if (helmetData) {
        return (data = { ...data, ...helmetData });
      }

      return data;
    });

    return result;
  }
};

export const getWeeklyLeadersWins = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;

      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/weeklyLeadersWins?page=${page}`);
      } else {
        dispatch({
          type: types.GET_WEEKLY_LEADERS_WINS_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/weeklyLeadersWins`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_WEEKLY_LEADERS_WINS_SUCCESS,
        payload: { weeklyLeadersWins: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_WEEKLY_LEADERS_WINS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getWeeklyLeadersRatings = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;

      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/weeklyLeadersRatings?page=${page}`);
      } else {
        dispatch({
          type: types.GET_WEEKLY_LEADERS_RATINGS_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/weeklyLeadersRatings`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_WEEKLY_LEADERS_RATINGS_SUCCESS,
        payload: { weeklyLeadersRatings: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_WEEKLY_LEADERS_RATINGS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getTeamLeadersWins = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;
      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersWins?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_WINS_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersWins`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_WINS_SUCCESS,
        payload: { teamLeadersWins: res },
      });
    } catch (error: any) {
      console.log('error', error?.message);
      dispatch({
        type: types.GET_TEAM_LEADERS_WINS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
        // error: 'error?.response?.data?.error || error?.message || error',
      });
    }
  };
};

export const getTeamLeadersPlayed = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;

      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersPlayed?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_PLAYED_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersPlayed`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_PLAYED_SUCCESS,
        payload: { teamLeadersPlayed: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_LEADERS_PLAYED_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getTeamLeadersScored = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;
      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersScored?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_SCORED_REQUEST,
          payload: {},
        });

        res = await get(`/engine/team/teamLeadersScored`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_SCORED_SUCCESS,
        payload: { teamLeadersScored: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_LEADERS_SCORED_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getTeamLeadersConsWins = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;
      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersConsWins?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_CONS_WINS_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersConsWins`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_CONS_WINS_SUCCESS,
        payload: { teamLeadersConsWins: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_LEADERS_CONS_WINS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getTeamLeadersWinrate = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;
      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersWinrate?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_WINRATE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersWinrate`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_WINRATE_SUCCESS,
        payload: { teamLeadersWinrate: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_LEADERS_WINRATE_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getTeamLeadersPlayoffs = (page: number | void) => {
  return async (dispatch: any, getState: any) => {
    try {
      let res: any;
      if (page) {
        dispatch({
          type: types.TEAM_LEADERS_VIEW_MORE_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersPlayoffs?page=${page}`);
      } else {
        dispatch({
          type: types.GET_TEAM_LEADERS_PLAYOFFS_REQUEST,
          payload: {},
        });
        res = await get(`/engine/team/teamLeadersPlayoffs`);
      }

      const dataWithHelmets = await addTeamsAndHelmetsData(res);
      if (dataWithHelmets) {
        res = dataWithHelmets;
      }

      if (page) {
        dispatch({
          type: types.SET_TEAM_LEADERS_VIEW_MORE_DATA,
          payload: { teamLeadersViewMoreData: res },
        });

        return;
      }

      dispatch({
        type: types.GET_TEAM_LEADERS_PLAYOFFS_SUCCESS,
        payload: { teamLeadersPlayoffs: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_LEADERS_PLAYOFFS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const setTeamLeadersViewMoreType = (type: keyof typeof ViewMoreTypes | null) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_TEAM_LEADERS_VIEW_MORE_TYPE,
      payload: { teamLeadersViewMoreType: type },
    });
  };
};

export const cleanTeamLeadersViewMoreData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.CLEAN_TEAM_LEADERS_VIEW_MORE_DATA,
      payload: {},
    });
  };
};

export const setPlayerLeaderType = (leaderType?: SkillEnum | null) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_PLAYERS_LEADERS_TYPE,
      payload: { player_leaders_type: leaderType },
    });
  };
};
export const cleanPlayerLeaderTypeData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_PLAYERS_LEADERS_TYPE_DATA,
      payload: { player_leaders_type: null },
    });
  };
};

export const getPlayerLeaders = (leaderType?: SkillEnum) => {
  return async (dispatch: any) => {
    try {
      let leaders: any;
      if (leaderType) {
        leaders = await get(`/engine/players/leaders?type=${leaderType}`);
        dispatch({
          type: types.SET_PLAYERS_LEADERS_TYPE_DATA,
          payload: { player_leaders_type_data: leaders },
        });
      } else {
        dispatch({
          type: types.GET_PLAYERS_LEADERS_REQUEST,
          payload: {},
        });
        leaders = await get(`/engine/players/leaders`);
        dispatch({
          type: types.GET_PLAYERS_LEADERS_SUCCESS,
          payload: { leaders },
        });
      }
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.GET_PLAYERS_LEADERS_FAIL,
        error: error?.response?.data?.error ?? 'Unexpected error',
      });
    }
  };
};

export const leaveGame = (gid: number, type: League['type'], engId: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.LEAVE_GAME_REQUEST,
        payload: {},
      });
      const res: any = await post(`/engine/games/${gid}/leave`, { type, utid: engId });
      if (res.newUserBalance && !Number.isNaN(+res.newUserBalance)) {
        dispatch(updateWalletBalance(+res.newUserBalance));
      }
      const userId = getState()?.app?.resData?.id;
      if (userId) {
        dispatch(getUserActiveGames(userId));
      }

      dispatch({
        type: types.LEAVE_GAME_SUCCESS,
        payload: { leaveGameData: res },
      });
    } catch (error: any) {
      notification.error({
        placement: 'bottomRight',
        message: 'Something went wrong leaving a game',
      });
      dispatch({
        type: types.LEAVE_GAME_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const leaveGameDataClean = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: types.LEAVE_GAME_CLEAN,
    });
  };
};

export const cleanCreateLeagueData = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: types.CLEAN_CREATE_LEAGUE_DATA,
    });
  };
};

type CreateTournamentData = {
  userId: number;
  name: string;
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  numberOfTeams: number;
  startAt: Date;
  pregameTime: number;
  gameMode: string;
  paceOfPlay: string | null;
  type: 'E';
  trophyId: number | null;
  groupId: number;
};

type CreateHeadToHeadData = {
  name: string;
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  gameMode: string;
  type: 'H';
  groupId: number;
};

export const createLeague = (data: CreateTournamentData | CreateHeadToHeadData) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.CREATE_LEAGUE_REQUEST,
        payload: {},
      });

      const res: any = await put(`/engine/leagues/create`, data);

      dispatch({
        type: types.CREATE_LEAGUE_SUCCESS,
        payload: { createLeagueRes: res },
      });
    } catch (error: any) {
      notification.error({
        placement: 'bottomRight',
        message: error?.response?.data || 'Something went wrong creating a league',
      });
      dispatch({
        type: types.CREATE_LEAGUE_FAIL,
        error: error,
      });
    }
  };
};

export const getEliminationScores = (eliminationGid: number, page: number, round: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETGAMESEASONSCORES_REQUEST,
        payload: {},
      });
      let res: any;
      if (eliminationGid) {
        res = await get(`/engine/games/elimination/${eliminationGid}/h2hGamesScores?round=${round}`);
      } else {
        res = await get(`/engine/games/h2h/seasonScores?page=${page}`);
      }

      const avatars: any = await Promise.all(
        Array.from(new Set(res.data.map((data: { tid: number[] }) => data.tid).flat())).map((tid) =>
          get(
            `${API_URL}/user/get-avatar/${tid}`,
            {},
            {
              'Access-Control-Allow-Origin': '*',
            },
          ).catch(() => ({
            utid: tid,
            img: null,
          })),
        ),
      );

      const resWithAvatars = await addHelmetsForScores(res.data);

      if (eliminationGid) {
        const TeamData = getState()?.team?.userTeamsList;
        if (TeamData && TeamData?.length) {
          const userTeamIds = TeamData.map((team: any) => team?.engId);
          const sortedData = resWithAvatars.reduce((acc: any[], curr: any) => {
            if (curr.tid?.some((id: number) => userTeamIds.includes(id))) {
              acc.unshift(curr);
            } else {
              acc.push(curr);
            }
            return acc;
          }, []);
          dispatch({
            type: types.GETGAMESEASONSCORES_SUCCESS,
            payload: { gameSeasonScores: { ...res, data: sortedData } },
          });

          return;
        }
      }

      dispatch({
        type: types.GETGAMESEASONSCORES_SUCCESS,
        payload: { gameSeasonScores: { ...res, data: resWithAvatars } },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETGAMESEASONSCORES_FAIL,
        error: error,
      });
    }
  };
};

export const getFranchisePerformance = (utid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_FRANCHISE_PERFORMANCE_REQUEST,
        payload: {},
      });
      const res: FranchisePerformance = await get(`/engine/team/${utid}/performance`);
      dispatch({
        type: types.GET_FRANCHISE_PERFORMANCE_SUCCESS,
        payload: { franchisePerformance: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_FRANCHISE_PERFORMANCE_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};

export const getUserActiveGamesDetails = (ownerId: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_TEAM_ACTIVE_GAMES_DETAILS_REQUEST,
        payload: {},
      });
      const result: any = await get(`/engine/owner/${ownerId}/activeGames?withDetails=true`);
      dispatch({
        type: types.GET_TEAM_ACTIVE_GAMES_DETAILS_SUCCESS,
        payload: {
          result,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_TEAM_ACTIVE_GAMES_DETAILS_FAIL,
        error: 'Something went Wrong',
      });
    }
  };
};

export const getPlayersStockReport = (utid: number, period?: TeamStatPriod) => {
  return async (dispatch: any, getState: any) => {
    if (getState().gameEngine.loaders.playersStockReport) return;
  
    try {
      dispatch({
        type: types.GET_PLAYERS_STOCK_REPORT_REQUEST,
        payload: {},
      });
      const res: any = await get(`/engine/team/${utid}/stockReport`,
        {
          period,
        }
      );
      dispatch({
        type: types.GET_PLAYERS_STOCK_REPORT_SUCCESS,
        payload: { playersStockReport: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_PLAYERS_STOCK_REPORT_FAIL,
        error: error,
      });
    }
  };
};
