import { RenderTrophy } from 'components/Common/RenderTrophy/RenderTrophy';
import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTrophiesByTeamUtid } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';
import history from '../../../../routes/history';
import { useParams } from 'react-router-dom';

const OverviewLeagueTrophyes: React.FC = () => {
  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();

  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }
  const trophies = useSelector((state: RootState) => state.gameEngine.teamTrophies);
  const dispatch = useDispatch();

  useEffect(() => {
    if (franchiseId) {
      dispatch(getTrophiesByTeamUtid(franchiseId));
    }
  }, [franchiseId]);

  return (
    <div className="mb-5">
      <h5 className="title">TEAM TROPHY & AWARDS</h5>
      {!!trophies.length ?
        <Row>
          {trophies.map((id) => {
            return (
              <React.Fragment key={id}>
                <RenderTrophy
                  trophyId={+id}
                  mode="cup"
                  style={{ width: "80px", height: "100%" }}
                />
              </React.Fragment>)
          })}
        </Row>
        :
        <Row>
          <h5>No trophies</h5>
        </Row>
      }
    </div>
  );
};

export default OverviewLeagueTrophyes;
