import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { SeasonInfo } from 'screens/Season/types';
import { useWeekDetails } from './hooks/useWeekDetails';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import history from 'routes/history';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

type Props = {
  week: SeasonInfo['groupStage']['weeks'][0];
};

export const SeasonScheduleListItem: React.FC<Props> = ({ week }) => {
  const { loadWeekDetails, weekDetails, loading } = useWeekDetails(week?.id);

  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);

  let arrowCN = 'season-schedule-list-item-more';
  let dataCN = 'season-schedule-list-item-more-data';

  if (!!weekDetails) {
    arrowCN += ' season-schedule-list-item-more__expanded';
    dataCN += ' season-schedule-list-item-more-data__expanded';
  }

  return (
    <>
      <div className="season-schedule-list-item">
        <span className="season-schedule-list-item-name">
          <span>{week.title.split(' ')[0]}</span>
          <span>&nbsp;{week.title.split(' ')[1]}</span>
        </span>

        <span>START AT: {new Date(week.days[0].scheduledAt).toLocaleString()}</span>

        <span>GAME SERIES: {week.days.length}</span>

        {loading ? <LoadingOutlined /> : <DownOutlined className={arrowCN} onClick={loadWeekDetails} />}
      </div>
      <div className={dataCN}>
        {weekDetails?.length
          ? weekDetails.map((day, i) => (
              <div key={i} className="day">
                <span className="day-number">SERIES {day.day_number}, </span>
                <span className="day-start-at">START AT: {new Date(day.scheduledAt).toLocaleString()}</span>
                <div className="day-games">
                  {day.games.map((g) => (
                    <div key={g.gid} className="day-games-item">
                      <div className="day-games-item-team">
                        {g?.helmets?.[0] && (
                          <RenderHelmet
                            helmet={g.helmets[0].helmet}
                            logo={g.helmets[0].logo}
                            mirror
                            size={100}
                            onClick={() => history.push(`/franchisedetail/${g?.helmets?.[0]?.engId}`)}
                          />
                        )}
                        <span>{g?.helmets?.[0]?.teamName || 'UNKNOWN'}</span>
                      </div>
                      <div className="day-games-item-center">
                        <span className="day-games-item-center-score">{g.score && g.score[g.teams[0]] && g.score[g.teams[1]] && `${g.score[g.teams[0]]} - ${g.score[g.teams[1]]}`}</span>
                        <BlueButton
                          text={g.score && g.score[g.teams[0]] && g.score[g.teams[1]] ? 'RESULTS' : 'WATCH'}
                          disabled={!g?.gid}
                          onClick={() => {
                            if (g.score && g.score[g.teams[0]] && g.score[g.teams[1]]) {
                              history.push(`/game-results/${g.gid}`);
                            } else if (userTeams?.some((t) => g.teams.includes(t.engId))) {
                              history.push('/leagueheadtohead', g.gid);
                            } else {
                              history.push(`/gameplay?id=${g.gid}`);
                            }
                          }}
                        />
                      </div>
                      <div className="day-games-item-team day-games-item-team-right">
                        {g?.helmets?.[1] && (
                          <RenderHelmet
                            helmet={g.helmets[1].helmet}
                            logo={g.helmets[1].logo}
                            size={100}
                            onClick={() => history.push(`/franchisedetail/${g?.helmets?.[1]?.engId}`)}
                          />
                        )}
                        <span>{g?.helmets?.[1]?.teamName || 'UNKNOWN'}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : week.days.map((day, i) => (
              <div key={i}>
                <span>{day.title}</span>
                <span>&nbsp;START AT: {new Date(day.scheduledAt).toLocaleString()}</span>
              </div>
            ))}
      </div>
    </>
  );
};
