import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import { Modal, notification } from "antd";
import WhiteButton from "../Common/Button/WhiteButton/WhiteButton";
import BelowTeamRatingModal from "./BelowTeamRatingModal";
import HigherTeamRatingModal from "./HigherTeamRatingModal";
import history from "./../../routes/history";
import Error from "../../assets/images/common/error.svg";
import PinkButton from "../Common/Button/PinkButton/PinkButton";
import { Link } from "react-router-dom";
import { useEnterGame } from "./hooks/useEnterGame";
import { FreeGameWarningModal } from "./FreeGameWarningModal";
import { useWarningModal } from "./hooks/use-warning-modal";
import { getPrizePool, getTeamsInGameString } from "helper/helper";
import { EliminationTeamsTooltip } from "components/FranchiseOverview/TabDetail/OverviewTab/EliminationTeamsTooltip";
import { RenderTrophy } from "components/Common/RenderTrophy/RenderTrophy";
import { getFormattedName } from "./helper";
import { LeaveGameButton } from "screens/EnteredLeague/hooks/LeaveGameButton";
import { useFeeRates } from "helper/useFeeRates";

export interface Props {
  data?: any;
}

const LeagueAvailableList = ({ data }: Props) => {
   const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const leagueEnterFormData = useSelector(
    (state: RootState) => state.team.leagueEnterFormData
  );

  const isGameFree = data.fee === 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isPromoUsedByUser = useSelector((state: RootState) => state.app.isPromoUsed)

  //MODAL 1
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // MODAL 2
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  // MODAL 3
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const showModal3 = () => {
    setIsModalVisible3(true);
  };
  const handleOk3 = () => {
    setIsModalVisible3(false);
  };
  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };

  // join the league
  const handleJoinLeague = () => {
   if (data?.type === "S" && data?.fee > 0) {
      notification.error({
         message: "Paid season join is not available yet",
      });
      return;
   }
    let minRating = Number(data?.teamrating?.split(" ")?.[0]);
    let maxRating = Number(data?.teamrating?.split(" ")?.[2]);
    if (!minRating || !maxRating) {
      minRating = data?.ratingRangeMin;
      maxRating = data?.ratingRangeMax;
    }
    const franchiseRating = Number(leagueEnterFormData?.franchise?.ovr);

    if (!franchiseRating) {
      showModal3();
    }
    if (minRating <= franchiseRating && franchiseRating <= maxRating) {
      if (isGameFree && data?.type === "H") {
         joinGame();
         return;
      }
      history.push("/leaguepayment", {
        ...data,
        gid: data?.gid || data?.seasonId,
        franchise: leagueEnterFormData?.franchise,
      });
    }

    if (franchiseRating && franchiseRating < minRating) {
      showModal1();
    }

    if (franchiseRating && franchiseRating > maxRating) {
      showModal();
    }
  };

  const handleRedirectToWallet = () => history.push('/setting?tab=addFunds&redirectBack=league')

  const getLeaguePrice = () => {
    if (isGameFree) {
      return "FREE";
    }

    if (!Number.isNaN(Number(data.fee))) {
      return (
         <>
           <sup>$</sup>
           {data.fee}
         </>
       );
    }

    return <p className="font14 mb-0">{data.fee}</p>;
  };

  const {
     isModalVisible: IsWarningModalVisible,
     // eslint-disable-next-line @typescript-eslint/no-unused-vars
     showModal: showWarningModal,
     handleOk: handleWarningModalOK,
     handleCancel: handleWarningModalCancel,
  } = useWarningModal(handleRedirectToWallet, handleJoinLeague);

  const { isDisabled, joinGame } = useEnterGame({
      engId: leagueEnterFormData?.franchise?.engId,
      type: data?.type,
      gid: data?.gid,
  });

  const handleJoinWithWarning = () => {
   // temporarily disabledd
   //  if (isGameFree && !isPromoUsedByUser) {
   //     showWarningModal()
   //     return
   //  }

    handleJoinLeague();
  };

  const teamsString = (data && data.numberOfTeams && data.numberOfTeams !== 2) 
   ? `TEAMS: ${getTeamsInGameString(data.numberOfTeams, data.teams.length, true)}`
   : data && data.fee && data.fee === 'YOUR CHOICE' 
      ? 'TEAMS: 0 of CUSTOM'
      : 'totalTeams' in data ? `TEAMS ${data?.teams?.length || 0} of ${data.totalTeams}`: `TEAMS: ${data?.teams?.length} of 2`;

   const startsAtString = (["E", "S"].includes(data?.type) && data?.startAt) 
      ? (
         <>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span>
               STARTS : {data.startAt && `${new Date(data.startAt).toLocaleString()} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}
            </span>
         </>
      )
      : "";

   const payoutPct = data?.payoutPct || (WALLET_TRANSACTION_FEE !== undefined ? 100 - WALLET_TRANSACTION_FEE : 0);
   const prizePool = (Number.isNaN(+data.fee))
      ? 'CUSTOM'
      : `$ ${getPrizePool(data.fee, payoutPct, data.numberOfTeams )}`;

   const teamRating = (data && data.ratingRangeMin && data.ratingRangeMax) 
      ? `${data.ratingRangeMin} TO ${data.ratingRangeMax}` 
      : 'ANY';
   
   const isGamePrepared = !!data?.prepared;

   const renderButton = () => {
      const isGameJoined = !!data?.isGameJoined;
      const isPossibleToLeave = (
         typeof data?.status === 'string' 
            && ['waitingForPlayers', ''].includes(data.status)
      );
      const followCallback = () => {
         if (isPossibleToLeave) {
            history.push(`/EnteredLeague?gid=${data?.gid}&type=${data?.type}`);
         } else if (data?.status === 'inProgress' && data?.gid && data?.type === 'H') {
            history.push(`/gameplay?id=${data?.gid}`);
         } else if (data?.status === 'pregame' && data?.gid) {
            history.push('/leagueheadtohead', data.gid);
         } else if ((data?.status === 'inProgress' || data?.status === 'waitingForStart') && data?.gid && data?.type === 'E') {
            history.push(`/leagueelimination?gid=${data.gid}`);
         } else if (data?.type === "S") {
            if (data?.status === "pending") {
               history.push(`/EnteredLeague?gid=${data?.gid}&type=${data?.type}`);
               return;
            }

            history.push(`/season/${data?.gid}`);
         }
      };

      const FollowButton = () => (
         <PinkButton
            text="FOLLOW"
            onClick={followCallback}
            className="join-game-button"
         />
      );

      const LeaveButton = () => (
         <LeaveGameButton 
            game={data}
            isGameList
         />
      )

      if (isGameJoined) {
         return (
            <>
               <FollowButton />
               {isPossibleToLeave && (
                  <LeaveButton />
               )}
            </>
         )
      }

      return ( 
         <>
            {data?.teams?.length > 0 ? (
               <PinkButton
                  disabled={isGameFree && isDisabled}
                  text='JOIN'
                  onClick={handleJoinWithWarning}
                  className="join-game-button"
                  id={isGamePrepared ? 'preparedLeagueJoinButton' : ''}
               />
            ) : data.handleCreate 
            ? (
            <PinkButton
                  disabled={isGameFree && isDisabled}
                  text='CREATE'
                  onClick={data.handleCreate}
                  className="join-game-button"
               />
            )
            :(
               <BlueButton
                  disabled={isGameFree && isDisabled}
                  text='JOIN'
                  onClick={handleJoinWithWarning}
                  className="join-game-button"
               />
            )}
         </>
      )
   }

  return (
     <>
        <div className={isGamePrepared ? 'leagueAvailableMain preparedLeague': 'leagueAvailableMain'} style={data.fee === 'YOUR CHOICE' ? { backgroundColor: "#f4f7f7"} : {}}>
           <Row>
              <Col xl={1} lg={1} sm={2}>
                 <div className='leaguePrice' style={data.fee === 'YOUR CHOICE' ? { backgroundColor: "#d1403c"} : {}} >
                    <h3>{getLeaguePrice()}</h3>
                 </div>
              </Col>
              <Col xl={9} lg={9} sm={10}>
                 <div className='leagueDetail'>
                    <h6>{data.fee === 'YOUR CHOICE' ? data.name : getFormattedName(data.name, data.type)}</h6>
                    <div className='leagueDetailSection'>
                       <div>
                          <span>{teamsString}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span>
                             PRIZE: {prizePool}
                          </span>{' '}
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <span>
                             RATING: {teamRating}
                          </span>
                          {startsAtString}
                          <div className="cupContainer">
                          {data?.trophyId && (
                           <RenderTrophy 
                              trophyId={data?.trophyId}
                              mode="cup"
                              style={{ width: "40px", height: "51px"}}
                           />
                          )}
                          </div>
                       </div>
                       {data && data?.teamName && data?.teams?.[0] && data?.type === "H" && (
                          <div>
                             <Link
                                className='franchiseLink'
                                to={`/franchisedetail/${data?.teams?.[0]}`}
                             >
                                {data?.teamName}
                             </Link>
                             <p> is waiting for opponent</p>
                          </div>
                       )}
                       {data && data?.teamNames && data.teamNames.length && (
                           <EliminationTeamsTooltip 
                              text={data.teamNames.join(', ')}
                              containerClassName="leagueAvailableTeamNames"
                           >
                              <p className="teamNamesTextContainer"><div>{data.teamNames.join(', ')}</div></p>
                           </EliminationTeamsTooltip>
                       )}
                    </div>
                 </div>
              </Col>
              <Col xl={2} lg={2} md={12} sm={12} className='joinButton'>
                 {renderButton()}
              </Col>
           </Row>
        </div>

        {/* MODAL 1 */}
        <Modal
           title='Basic Modal'
           className='draftcommon leaguerating'
           open={isModalVisible}
           onOk={handleOk}
           onCancel={handleCancel}
           footer={[
              <WhiteButton onClick={handleCancel} text='CANCEL' />,
              <BlueButton onClick={handleOk} text='DONE' />,
           ]}
           centered
        >
           <BelowTeamRatingModal />
        </Modal>

        {/* MODAL 2 */}
        <Modal
           title='Basic Modal'
           className='draftcommon leaguerating'
           open={isModalVisible1}
           onOk={handleOk1}
           onCancel={handleCancel1}
           footer={[
              <WhiteButton onClick={handleCancel} text='CANCEL' />,
              <BlueButton onClick={handleOk1} text='JOIN' />,
           ]}
           centered
        >
           <HigherTeamRatingModal />
        </Modal>

        {/* MODAL 3 */}
        <Modal
           title='Basic Modal'
           className='draftcommon leaguerating'
           open={isModalVisible3}
           onOk={handleOk3}
           onCancel={handleCancel3}
           footer={[<WhiteButton onClick={handleCancel3} text='OK' />]}
           centered
        >
           <div className='startdraftmain'>
              <img src={Error} alt='erroricon' /> 
              <p> Please Select Franchise</p>
           </div>
        </Modal>

        {/* MODAL 4 REGARDING WARNING WHEN FREE GAME IS BEING JOINED */}
        <FreeGameWarningModal
           open={IsWarningModalVisible}
           onCancel={handleWarningModalCancel}
           onOk={handleWarningModalOK}
        />
     </>
  )
};

export default LeagueAvailableList;
